.app__productitem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
     
    padding: 20px;


    border: 2px solid silver; 
    background: radial-gradient(circle at 50% 50%, rgba(66, 66, 65, 1) 0%, rgba(0, 0, 0, 1) 100%);
    color: #fff; /* Button text color */
 
 
 
  transition: background-color 0.3s ease; /* Smooth transition on hover */
 
  }
  
  .app__productitem img {
    width: 50%; /* Adjust image width to fit the card */
    height: auto;
    border-radius: 8px; /* Apply border radius to the image */
    margin-bottom: 20px; /* Add some spacing below the image */
  }
  
  .app__productitem h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .app__productitem p {
    font-size: 16px;
    color: white; /* Change text color */
  }
  