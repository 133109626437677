.footer-container {
  background:  linear-gradient(90deg, rgba(80, 80, 75, 1) 0%, rgba(43, 49, 50, 1) 50%, rgba(16, 16, 16, 1) 99%);
 
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .left {
    width: 50%;
  }
  
  .right {
    width: 50%;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  .right a {
    color: white;
    text-decoration: none;
    margin-left: 10px;
  }
  
  .right a:hover {
    color: lightblue;
  }
  /* Increase the size of the image */
  .right a svg {
    height: 30px; /* Adjust the height as needed */
    width: 30px; /* Adjust the width as needed */
  }

  @media only screen and (max-width: 768px) {
    .footer-container {
      flex-direction: column; /* Change back to row for larger screens */
      justify-content: center;
      align-items: center;
    }
    .right {
      justify-content: center;
      align-items: center;
    }
     
  }