.image-card {
  width: 95%;
  height: 250px;
  margin: 0 10px; /* Add some spacing between cards */
  overflow: hidden;
  position: relative;
  display: flex;
  border: 2px solid #818582;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit:cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-card:hover .overlay {
  opacity: 1;
}
