.app__tab1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.explore_more-button {
    margin-top: 20px;
    padding: 12px 60px;
    background: var(--gradient-bg);
    color: #fff; /* Button text color */
    border: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
    margin-top: 15px;
    margin-bottom: 10px;
}