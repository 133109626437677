* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
 
               
 
  /* global 92%+ browsers support */
  background: radial-gradient(circle at 50% 50%, rgba(66, 66, 65, 1) 0%, rgba(0, 0, 0, 1) 100%);
}
          

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.custom__button {
    background: radial-gradient(circle at 50% 50%, rgba(197, 122, 140, 1) 0%, rgba(93, 1, 1, 1) 100%);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    color: rgb(206, 200, 200);
    font-size: 16px;
    justify-content: flex-start;
    border-radius: 1px;
    width: 300px;
    align-items: center;
    border: none;

    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  line-height: 29.9px;
  font-size: 23px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;

}

.p__opensans {
 
  color: white;
  font-weight: 100;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 27px;
  font-size: 14px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: white;
  font-size: 44px;
  line-height: 56.2px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
   
     
   
}
.section__padding{
  padding: 2rem 4rem;
}
.section__margin{
  margin: 0 2rem 0 2rem;
}
.gradient__bg{
   
 
    background: radial-gradient(circle at 50% 50%, rgba(66, 66, 65, 1) 0%, rgba(0, 0, 0, 1) 100%);
 
          

}
.app__wrapper {
  display: flex;
   
  align-items: center;

  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}