.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
    background: var(--color-black);
    padding: 0 2rem; /* Adjust padding for smaller screens */
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 8px;
}

.app__navbar-logo img {
    width: 80px;
}

.app__navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

.app__navbar-links li {
    margin: 0 1rem;
    font-size: 20px;
    cursor: pointer;
    font-weight: 500;
}
.app__navbar-links li a{ 
    text-decoration: none; /* Remove underline from anchor text */
    color: white; /* Set anchor text color */
    transition: color 0.3s ease; /* Add transition effect for color change */

}

.app__navbar-links li:hover {
    color: gray;
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login img {
    margin-right: 1rem;
    width: 30px;
    height: 30px;
}

.app__navbar-login button {
    margin-right: 1rem;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--gradient-bg);
    transition: .5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
    padding: 0; /* Remove default padding */
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}
.app__navbar-smallscreen_links li a{
    text-decoration: none; /* Remove underline from anchor text */
    color: white; /* Set anchor text color */
    transition: color 0.3s ease; /* Add transition effect for color change */

}
.custom-button {
    background: var(--gradient-bg);
    border: 1px solid var(--gradient-bg); 
    color: white;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 0;
    cursor: pointer;
    font-weight: 600;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}


.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 950px) {
    .app__navbar-links,
    .app__navbar-login {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
    .app__navbar-logo img {
        width: 60px;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-logo img {
        width: 80px;
    }

    .app__navbar-login {
        display: flex; /* Display login section on smaller screens */
        align-items: center;
    }

    .app__navbar-login img,
    .app__navbar-login button {
        margin: 0 0.5rem; /* Adjust margin for better spacing */
    }
}