.technologies-container {
  text-align: center;
}

.technologies-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.gradient-line {
  width: 50px;
  height: 1px;
  background: var(--gradient-bg);
  margin: 0 auto 20px;
}

.box-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.box {
  
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 200px;
}

.box-heading {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 700;
}

.box-description {
  font-size: 14px;
  color: #fff;
}
.technologies-container.section__padding {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.technologies-container.section__padding.show {
  opacity: 1;
  transform: translateY(0);
}
