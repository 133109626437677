.project-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Two items in one row */
    gap: 2rem; /* Adjust the gap between items */
    justify-items: center; /* Center align items horizontally */
    margin-top: 4rem;
  }
  
  .review-item {
    margin: 1rem; /* Margin around each item */
  }
  
  
  .zoomed-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .zoomed-image-overlay img {
    max-width: 80%;
    max-height: 80%;
  }
  