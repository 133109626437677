.app__getintouch{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
   
  
    height: 600px;
    background: rgb(27, 26, 26);
}
.app__getintouch-leftside{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
  
     
}
.app__getintouch-leftside img {
  display: flex;
  height: auto;
 
 
}
.app__getintouch-rightside{
    display: flex;
    flex: 1;
    padding: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    
      
}
 .app__getintouch-input-fields{
  display: flex;
  
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;

 }
.custom-input {
    margin-top: 10px;
    width: 100%;
 
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
   /* Adjust spacing between input fields */
    outline: none;
  }
  
  .custom-input:focus {
    border-color: maroon; /* Change border color on focus */
  }
  
  .custom-textarea {
    width: 100%; /* Subtract the total margin and padding of the input fields */
    
    height: 150px;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 10px; /* Adjust spacing from input fields */
    resize: vertical; /* Allow vertical resizing */
    outline: none;
  }
  
  .custom-textarea:focus {
    border-color: maroon; /* Change border color on focus */
  }
  
  /* Style for placeholder text in textarea */
  .custom-textarea::placeholder {
    color: #aaa; /* Adjust placeholder text color */
  }
  
  /* Style for textarea when user starts typing */
  .custom-textarea:focus::placeholder {
    color: transparent; /* Hide placeholder text when textarea is focused */
  }
  /* Styles for the send button */
.send-button {
  padding: 12px 60px;
  background: var(--gradient-bg);
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  margin-top: 15px;
  margin-bottom: 10px;
}

.send-button:hover {
  background-color: #0056b3; /* Darker background color on hover */
}
.app__getintouch-texbox-btn{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .app__getintouch {
    flex-direction: column;
    height: auto; /* Set height to auto for tablets */
     
  }

  .app__getintouch-rightside {
    padding: 10px; /* Adjust padding on smaller screens */
  }

  .app__getintouch-leftside img {
 
    width:80%;
   
  }
  .send-button {
    padding: 30px 160px;
    font-size: 20px;
  }
   
}


@media (max-width: 768px) {
  .app__getintouch {
    flex-direction: column; /* Change to column layout on smaller screens */
  }

  .app__getintouch-rightside {
    padding: 10px; /* Adjust padding on smaller screens */
  }

  .custom-input {
    padding: 8px;
  }
  .send-button {
    padding: 15px 80px;
    font-size: 20px;
  }
}