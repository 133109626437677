.app__home {
    display: flex;
     min-height: 600px;
    align-items: center;
}

.app__home-leftside {
    flex: 1;
    width: 100%;
     
    display: flex;
   
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; 
    gap: 10px;
    padding: 0 20px; /* Add padding to the sides */
}
.heading{
    height: 100px;
}
.app__home-leftside-welcom_div {

    background:  linear-gradient(90deg, rgba(80, 80, 75, 1) 0%, rgba(43, 49, 50, 1) 50%, rgba(16, 16, 16, 1) 99%);
 
    font-weight: 700;
    display: flex;
    letter-spacing: 0.04em;
    color: rgb(206, 200, 200);
    font-size: 18px;
    max-width: 270px;
    padding: 4px 6px;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
   
    height: auto;
}

.app__home-leftside-welcom_div p {
    
    margin: 0;
}

.app__home-rightside {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}
.image-frame {
    width: 300px;
    height: 300px;
    border: 2px solid transparent;
    border-image: var(--gradient-bg);
    border-image-slice: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
.app__home-rightside img {
    height: 100%; /* Ensure the image doesn't exceed the width of the frame */
    width: 100%; /* Ensure the image doesn't exceed the height of the frame */
    object-fit: contain; /* Maintain the aspect ratio of the image within the frame */
}
 

@media only screen and (max-width: 1150px) {
    .app__home {
        flex-direction: column;
        min-height: 60vh;
    }
    
    .heading{
        height: 40px;
    }
    
    .app__home-rightside {
        margin-left: 0;
        margin-top: 2rem; /* Adjust margin for smaller screens */
    }
}
@media only screen and (max-width: 767px) {
    .heading{
        height: 100px;
    }
}