.personal-achievements-container {
    text-align: center;
    height: auto;
    padding: 5px;
  }
  
  .heading {
    margin-bottom: 20px;
    color: white;
    font-size: 44px;
    line-height: 56.2px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
  }
  
  .personal-achievements {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .achievement-box {
    width: calc(25% - 20px); /* Adjust as needed */
    background:  linear-gradient(90deg, rgba(80, 80, 75, 1) 0%, rgba(43, 49, 50, 1) 50%, rgba(16, 16, 16, 1) 99%);
 
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 10px; /* Adding border radius */
  }
  
  .title {
    color: #fff;
  }
  
  .description {
    color: #fff;
  }
  .personal-achievements-container.section__margin {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .personal-achievements-container.section__margin.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media screen and (max-width: 992px) {
    .achievement-box {
      width: calc(50% - 20px);
    }
    .heading {
      margin-bottom: 20px;
      color: white;
      font-size: 40px;
      line-height: 46.2px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
    }
    
  }
  @media screen and (max-width: 650px){
    .achievement-box {
        padding: 10px;
    }
    .title {
        font-size: 16px;
        line-height: 10px;
      }
      
      .description {
        font-size: 12px;
      }
      
  }
  