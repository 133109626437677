.app__projects{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  height:auto;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;

  
}
.app__projects.show {
  opacity: 1;
  transform: translateY(0);
}
.app__projects-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app__projects-content p {
  max-width: 450px;

}
.tabs-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-button {
  color: white;
  background-color: #4a4a49;
  border: none;
  border-right: 1px solid #565555; /* Add right border */
  padding: 10px 40px; /* Decrease padding */
  cursor: pointer;
  outline: none;
}

/* Remove right border for the last button */
.tab-button:last-child {
  border-right: none;
}

.active-tab {
  background: var(--gradient-bg);
  font-weight: 700;
  color: white;
}

/* Add styles for the tab content */
.tab-content {
 
  overflow-y: auto; /* Add vertical scrollbar when content exceeds available space */
  padding-top: 50px; /* Add padding to the top */
 
}

/* Add styles for the tab container */
.tabs-container {
  display: flex;
  overflow-x: auto; /* Add horizontal scrollbar if tabs exceed container width */
  margin-bottom: 10px; /* Add some space between tabs and content */
}

/* Responsive Styles */
 
 
@media only screen and (max-width: 768px) {
  .tab-button {
    padding: 10px 20px; /* Adjust padding for smaller screens */
  }
  .app__projects{
    min-height: 40vh;
  }
}

@media only screen and (max-width: 576px) {
  .app__projects-content {
    text-align: center; /* Center-align the text on smaller screens */
    padding: 5px;
  }
  .tabs-container{
    padding: 0 10px;
  }
  .tabs-container button{
    font-size: 12px;
  }

  .app__projects-content p {
    max-width: none; /* Remove max-width constraint for better responsiveness */
  }
}

