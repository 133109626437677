.section__size {
    
    background-color: rgb(22, 22, 22);
    border-radius: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .app__skills-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .app__skills-progress {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Ensure items wrap to the next line on smaller screens */
    align-items: center;
    justify-content: space-around; /* Adjust spacing between items */
  }
  .app__skills-progress p{
    margin-top: 5px;
  }
  
  .app__skills-progress div {
    width: calc(33.33% - 20px); /* Adjust width to fit three items per row with margin */
    margin: 10px; /* Add margin between items */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .section__padding.section__size.section__margin {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .section__padding.section__size.section__margin.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media only screen and (max-width: 768px) {
    .app__skills-progress div {
      width: calc(50% - 20px); /* Adjust width to fit two items per row with margin */
    }
  }
  
  @media only screen and (max-width: 480px) {
    .app__skills-progress div {
      width: calc(100% - 20px); /* Adjust width to fit one item per row with margin */
    }
  }
  