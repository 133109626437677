.app__education{
  background: linear-gradient(90deg, rgba(16, 16, 16, 1) 0%, rgba(43, 49, 50, 1) 50%, rgba(16, 16, 16, 1) 99%);
 padding: 20px;
}
.education-heading {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .education-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .education-item {
    width: 300px;
    height: 200px;
    margin: 10px;
    border: 2px solid transparent;
    border-radius: 10px;
    transition: border-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .education-item:hover {
    border-color: var(--gradient-bg);
  }
  
  .year {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
  }
  
  .degree {
    font-size: 18px;
    margin-bottom: 5px;
    color: rgb(240, 235, 235);
    text-align: center; /* Align degree text to the center */
  }
  
  .university {
    font-size: 16px;
    color: rgb(250, 230, 230);
    text-align: center; /* Align university text to the center */
  }
  .app__education.section__margin {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .app__education.section__margin.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media screen and (max-width: 768px) {
    .education-item {
      width: calc(100% - 20px); /* Adjusted width for smaller screens */
      max-width: 300px; /* Limit maximum width */
    }
  }